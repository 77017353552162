import React, { useCallback } from 'react';
import SkeletonReportGeneratorForm from 'components/SkeletonReportGeneratorForm';
import moment from 'moment';
import { getCameraRawData } from 'api/alpha/reports';
import Papa from 'papaparse';

const SkeletonReportGenerator = () => {
  const onSubmit = useCallback((values) => {
    const params = {
      'timestamp[gte]': moment(values.start_time).unix(),
      'timestamp[lte]': moment(values.end_time).unix(),
    };
    return getCameraRawData(params)
      .then(({ data }) => {
        const formattedData = data.map((datum) => {
          const result = {};
          // eslint-disable-next-line dot-notation
          result['_id'] = datum.camera_raw_data_id;
          result.timestamp = datum.timestamp;
          result.device_id = datum.device_id;
          result.camera_id = datum.camera_id;
          const track_id = JSON.parse(datum.track_id);
          if (track_id.length > 0) {
            const [first_track_id] = track_id;
            result['track_id[0]'] = first_track_id;
          }

          const bbox = JSON.parse(datum.bbox);
          if (bbox.length > 0) {
            const [bbox_data] = bbox;
            if (bbox_data.length > 0) {
              bbox_data.forEach((bbox_datum, index) => {
                result[`bbox[0][${index}]`] = bbox_datum;
              });
            }
          }

          const skeletons = JSON.parse(datum.skeleton);
          if (skeletons.length > 0) {
            const skeletonList = skeletons[0];
            if (skeletonList.length > 0) {
              const skeletonArrays = skeletonList[0];
              if (skeletonArrays.length > 0) {
                skeletonArrays.forEach((skeleton_data, index) => {
                  if (skeleton_data.length > 0) {
                    skeleton_data.forEach((skeleton_sub_data, subIndex) => {
                      result[`skeleton[0][0][${index}][${subIndex}]`] = skeleton_sub_data;
                    });
                  }
                });
              }
            }
          }

          const outs = JSON.parse(datum.out);
          if (outs.length > 0) {
            const outList = outs[0]
            if (outList.length > 0) {
              const outArray = outList[0];
              outArray.forEach((outData, index) => {
                result[`out[0][0][${index}]`] = outData;
              });
            }
          }

          const fallFilter = JSON.parse(datum.fall_filter);
          const [fall_filter_data] = fallFilter;
          result['fall_filter[0]'] = fall_filter_data;

          const waveFilter = JSON.parse(datum.wave_filter);
          const [wave_filter_data] = waveFilter;
          result['wave_filter[0]'] = wave_filter_data;

          const isBed = JSON.parse(datum.is_bed);
          const [is_bed_data] = isBed;
          result['is_bed[0]'] = is_bed_data;

          const behaviour = JSON.parse(datum.behaviour);
          const [behaviour_data] = behaviour;
          result['Behaviour[0]'] = behaviour_data;

          return result;
        });
        const csv = Papa.unparse(formattedData);

        const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        let csvURL = null;
        const start = moment(values.start_time).format('YYYY-MM-DD-h:mm-A');
        const end = moment(values.end_time).format('YYYY-MM-DD-h:mm-A');
        const filename = `${start}_to_${end}.csv`;
        if (navigator.msSaveBlob) {
          csvURL = navigator.msSaveBlob(csvData, filename);
        } else {
          csvURL = window.URL.createObjectURL(csvData);
        }

        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', filename);
        tempLink.click();
      });
  }, []);

  return (
    <SkeletonReportGeneratorForm onSubmit={onSubmit} />
  );
};

SkeletonReportGenerator.propTypes = {
};

export default SkeletonReportGenerator;
