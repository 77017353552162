import MKTypography from 'components/MaterialKit/MKTypography';
import MKBox from 'components/MaterialKit/MKBox';
import MKButton from 'components/MaterialKit/MKButton';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useAuth } from 'contexts/auth';
import { Grid, Modal, IconButton } from '@mui/material';
import Image from 'components/Image';
import AnchorMenu from 'components/AnchorMenu';
import { useWards } from 'contexts/wards';
import CloseIcon from '@mui/icons-material/Close';
import NewGoogleTranslateButtonSection from 'sections/NewGoogleTranslateButton';
import { getLocaleMap } from 'utils/locales';
import WardGrid from './WardGrid';
import VRWardCard from './vrWardCard';

const sortingOptions = [
  { value: 'ward_name', label: 'Ward Name' },
  { value: 'center_name', label: 'Center Name' },
  { value: 'ward_id', label: 'Ward ID' },
  { value: 'center_id', label: 'Center ID' },
];

const sortWardsBy = (wards, sortingParameter) => {
  if (!wards) return [];
  return wards.sort((a, b) => {
    if (a[sortingParameter] < b[sortingParameter]) return -1;
    if (a[sortingParameter] > b[sortingParameter]) return 1;
    return 0;
  });
};

const AVHome = () => {
  const prepareWards = (wards) => {
    const wardsMap = wards?.map((ward) => {
      return {
        ward_id: ward.ward_id,
        ward_name: ward.name,
        center_id: ward.center_id.center_id,
        center_name: ward.center_id.name,
        service_provider_id: ward.center_id.service_provider_id.service_provider_id,
        service_provider_name: ward.center_id.service_provider_id.name,
        alerts: ward.alert_count || 0,
        emergency_alarms: ward.emergency_count || 0,
        guests: ward.patients?.length || 0,
        patients: ward.patients,
        accidents: ward.accidents,
      };
    });
    return wardsMap;
  };
  const { auth, setAuth } = useAuth();
  const { wards, setWards, notifications, deleteNotification } = useWards();
  const [localeMapping, setLocaleMapping] = useState({});
  const user = auth?.user;
  const [sortBy, setSortBy] = useState('ward_id');
  const handleSortBy = (option) => {
    setSortBy(option);
  };

  useEffect(() => {
    getLocaleMap(['web_service', 'download', 'launch', 'welcome', 'sort_by'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const wardsTuple = useMemo(() => {
    const preparedWards = prepareWards(wards);
    const sorted = sortWardsBy(preparedWards, sortBy);
    return {
      withProblems: sorted.filter((el) => el?.alerts !== 0 || el?.emergency_alarms !== 0),
      noProblems: sorted.filter((el) => el?.alerts === 0 && el?.emergency_alarms === 0),
    };
  }, [sortBy, wards]);

  const [showVrButton, setShowVrButton] = useState(true);
  const [vr, setVr] = useState(false);
  const [vrCardIndex, setVrCardIndex] = useState(null);

  const startVr = () => {
    setVr(true);
    setVrCardIndex(wardsTuple.noProblems[0]?.ward_id);
  };

  const endVr = () => {
    setVr(false);
    setVrCardIndex(null);
  };

  const handleVrStateChange = () => {
    if (vr) {
      endVr();
    } else startVr();
  };

  const handleChecked = (ward) => {
    const index = wardsTuple.noProblems.findIndex((el) => el?.ward_id === ward.ward_id);
    if (index === wardsTuple.noProblems.length - 1) {
      endVr();
    } else {
      setVrCardIndex(wardsTuple.noProblems[index + 1]?.ward_id);
    }
  };

  const [menuAnchor, setMenuAnchor] = useState(null);
  const onOpenMenu = useCallback(({ currentTarget }) => setMenuAnchor(currentTarget), []);

  useEffect(() => {
    if (wardsTuple.withProblems.length !== 0) {
      setShowVrButton(false);
      setVrCardIndex(null);
    } else {
      setShowVrButton(true);
    }
  }, [wardsTuple.withProblems]);

  return (
    <MKBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <MKBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <MKTypography variant="body1" fontSize="16px" fontWeight="bold" color="#3583C5">
          {`${localeMapping.welcome}, `}
          {user?.display_name || 'Unknown user'}
        </MKTypography>
        <MKBox>
          {/* {showVrButton && (
          <MKButton
            color={vr ? 'gray' : '#3583C5'}
            variant="contained"
            size="medium"
            onClick={handleVrStateChange}
            sx={{ mr: '1rem', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px' }}
          >
            <MKTypography variant="body1" fontSize="16px" fontWeight="bold" color="white">
              { vr ? 'Started VR' : 'Start VR' }
            </MKTypography>
          </MKButton>
          )} */}
          <MKButton
            color="#3583C5"
            variant="text"
            size="medium"
            onClick={onOpenMenu}
            sx={{ backgroundColor: '#EEFEFF', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px' }}
          >
            <Image
              src="https://portal.alphavision.tech/alpha-vision-server/contents/files/1710744962322-filter_icon.png"
              width="20px"
              height="20px"
              marginRight="8px"
            />
            <MKTypography variant="body1" fontSize="16px" fontWeight="bold" color="#3583C5">
              {localeMapping.sort_by}
            </MKTypography>
          </MKButton>
        </MKBox>
      </MKBox>
      <MKBox mt={2}>
        <Grid id="mReady" container flexDirection="row">
          <Grid marginRight="10px">{localeMapping.web_service}</Grid>
          {/* <Grid marginRight="10px">
            <a type="warning" href="https://doraemon.camera666.com/WebViewer" target="_blank" rel="noreferrer">
              {localeMapping.download}
            </a>
          </Grid> */}
          <Grid>
            <a type="warning" href="WebViewer://" target="_self">{localeMapping.launch}</a>
          </Grid>
        </Grid>
        {notifications?.map((notification, index) => notification.value !== 0 && (
        <MKBox
          key={index}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ border: '1.5px solid',
            borderColor: '#3783C5',
            backgroundColor: '#EEFEFF',
            borderRadius: '8px',
            position: 'absolute', // Add this line
            top: `${index * 60}px`, // Add this line to position the boxes
            right: '0' }}
          p={1}
          zIndex="tooltip"
        >
          <MKTypography variant="body1" fontWeight="bold" fontSize="12px">{notification.message}</MKTypography>
          <IconButton size="small" onClick={() => deleteNotification(notification.notificationId)}>
            <CloseIcon />
          </IconButton>
        </MKBox>
        ))}
      </MKBox>
      { vr && vrCardIndex && (
        <MKBox mt={2}>
          <Grid container spacing={1.5}>
            <Grid item xs={6}>
              <VRWardCard ward={wardsTuple.noProblems.find((el) => el?.ward_id === vrCardIndex)} handleChecked={handleChecked} />
            </Grid>
          </Grid>
        </MKBox>
      )}
      <MKBox mt={2}>
        <WardGrid withProblems={wardsTuple.withProblems} normal={wardsTuple.noProblems.filter((el) => el?.ward_id !== vrCardIndex)} />
      </MKBox>

      <AnchorMenu
        items={sortingOptions}
        onClickItem={handleSortBy}
        anchor={menuAnchor}
        setAnchor={setMenuAnchor}
      />

      <NewGoogleTranslateButtonSection />
    </MKBox>
  );
};

export default AVHome;
